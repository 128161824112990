import { computed } from 'vue'
import { useAddressStore, useOnetimesStore, useSubscriptionsStore } from '@/stores/recharge'
import type { Onetime, Subscription, PaymentMethod } from '@rechargeapps/storefront-client'

export const usePaymentMethodItems = (paymentMethodFunction: () => PaymentMethod) => {
  const addressStore = useAddressStore()
  const subscriptionStore = useSubscriptionsStore()
  const onetimeStore = useOnetimesStore()

  const addresses = addressStore.useAddresses()
  subscriptionStore.useSubscriptions({ includeInactive: false })
  onetimeStore.useOnetimes()

  const loading = computed(() => addressStore.loading || subscriptionStore.loading || onetimeStore.loading)

  const pm = computed<PaymentMethod>(paymentMethodFunction)
  const addressIds = computed<number[]>(() => pm.value.include?.addresses?.map(i => i.id) || [])

  const allSubscriptions = computed(() => subscriptionStore.active || [])
  const allOnetimes = computed(() => onetimeStore.onetimes || [])

  const subscriptions = computed(() => (
    allSubscriptions.value.filter(i => addressIds.value.includes(i.address_id))
  ))

  const otherSubscriptions = computed(() => {
    const subIds = subscriptions.value.map(i => i.id)
    return allSubscriptions.value.filter(i => !subIds.includes(i.id))
  })

  const onetimes = computed(() => (
    allOnetimes.value.filter(i => addressIds.value.includes(i.address_id))
  ))

  const otherOnetimes = computed(() => {
    const subIds = onetimes.value.map(i => i.id)
    return allOnetimes.value.filter(i => !subIds.includes(i.id))
  })

  const allItems = computed(() => [ ...subscriptions.value, ...onetimes.value ])

  const allOtherItems = computed(() => [
    ...otherSubscriptions.value,
    ...otherOnetimes.value
  ])

  const itemsToMove = computed(() => {
    return allOtherItems.value.reduce<{ [key: string]: (Onetime | Subscription)[] }>((acc, item) => {
      const id = String(item.address_id)
      acc[id] ||= []
      acc[id].push(item)
      return acc
    }, {})
  })

  return {
    loading,
    addressIds,
    addresses,
    subscriptions,
    otherSubscriptions,
    itemsToMove,
    onetimes,
    otherOnetimes,
    allItems,
    allOtherItems
  }
}
