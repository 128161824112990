<template>
  <div>
    <div class="font-semibold pb-1">
      Subscriptions
    </div>
    <template v-if="subscriptions?.length">
      <ItemTitle
        v-for="subscription in subscriptions"
        :key="subscription.id"
        :item="subscription"
      />
    </template>
    <p
      v-else
      class="py-1"
    >
      No associated subscriptions.
    </p>
    <template v-if="onetimes?.length">
      <div class="pb-1 mt-2 font-semibold">
        One Time Purchases
      </div>
      <ItemTitle
        v-for="onetime in onetimes"
        :key="onetime.id"
        :item="onetime"
      />
    </template>
    <button
      v-if="allOtherItems?.length"
      class="button +slim w-full mt-3"
      @click="$app.sheet.open(PaymentMethodMoveItems, { pm })"
    >
      Move Items
    </button>
  </div>
</template>

<script setup lang="ts">
import { usePaymentMethodItems } from '@/services/recharge/payment-method'
import type { PaymentMethod } from '@rechargeapps/storefront-client'
const PaymentMethodMoveItems = () => import('@/components/account/sheets/PaymentMethodMoveItems.vue')
export interface Props {
  pm: PaymentMethod
}
const props = defineProps<Props>()
const {
  subscriptions,
  onetimes,
  allOtherItems
} = usePaymentMethodItems(() => props.pm)
</script>
