
<template>
  <SectionBlock>
    <div class="flex justify-between">
      <SkeletonLoader class="w-40 h-6" />
      <SkeletonLoader class="w-10 h-6" />
    </div>
    <hr class="-mx-4">
    <div class="flex flex-col gap-1">
      <SkeletonLoader class="w-40 h-6 my-1" />
      <SkeletonLoader class="w-48 h-5" />
      <SkeletonLoader class="w-64 h-5" />
      <SkeletonLoader class="w-72 h-5" />
    </div>
  </SectionBlock>
</template>
