<template>
  <div class="route-container">
    <Profile edit />
    <AccountDetails />
    <PaymentMethods v-if="$auth.recharge.customer" />
    <div class="flex-center w-full">
      <LogOutButton class="w-44" />
    </div>
  </div>
</template>

<script setup lang="ts">
useHead({
  title: 'Account'
})
</script>
