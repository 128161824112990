<template>
  <SvgIcon
    :name="name"
    class="w-8 h-5"
  />
</template>

<script setup lang="ts">
// select distinct card_brand from recharge.payment_source
// visa
// discover
// mastercard
// Apple Pay - Visa
// american_express
// Apple Pay - MasterCard
// Apple Pay - American Express
// Apple Pay - Discover
// unionpay
export interface Props {
  brand: string
}
const BRANDS = /(visa|discover|mastercard|american-express|unionpay)/
const props = defineProps<Props>()
const iconName = () => {
  const name = props.brand.toLowerCase().replace(/[\s_]+/, '-').match(BRANDS)?.[0]
  return name ? `card-${name}` : 'card-generic'
}
const name = iconName()
</script>
